
import Image from 'next/legacy/image';

const HomeSliderImage = ({slide, idx}) => {
    return (
        <Image 
            key={slide.image}
            src={slide.image}
            alt={slide.name}
            layout="responsive"
            height={160}
            width={900}
            quality={100}
            priority={idx === 0}
        />
    )
}

export default HomeSliderImage